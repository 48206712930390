<div class="td-dynamic-select-wrapper">
  <mat-form-field class="td-dynamic-select-field">
    <mat-select [formControl]="control"
                [placeholder]="label"
                [required]="required"
                [attr.name]="name"
                [multiple]="multiple">
      <mat-option *ngFor="let selection of selections" [value]="selection.value || selection">{{selection.label || selection}}</mat-option>
    </mat-select>
    <mat-hint>{{hint}}</mat-hint>
    <mat-error>
      <ng-template
        [ngTemplateOutlet]="errorMessageTemplate"
        [ngTemplateOutletContext]="{control: control, errors: control?.errors}">
      </ng-template>
    </mat-error>
  </mat-form-field>
</div>
