<h2 matDialogTitle i18n>Create a new project</h2>
<mat-dialog-content id="new_project_dialog" class="new-project push">
  <form [formGroup]="form" fxLayout="column">
    <mat-form-field>
      <input
        id="project_name"
        matInput
        class="project-name"
        placeholder="Project name"
        i18n-placeholder
        [formControl]="name"
      />
      <mat-error *ngIf="name.errors" i18n>Enter project name</mat-error>
    </mat-form-field>
    <div class="push-top push-bottom">
      <span i18n class="push-right-sm">Room parameters</span>
      <mat-radio-group *ngIf="templates" [formControl]="useTemplates">
        <mat-radio-button
          [disableRipple]="true"
          class="push-right-sm push-top-sm"
          [value]="false"
          i18n
          >New room</mat-radio-button
        >
        <mat-radio-button
          [disableRipple]="true"
          class="push-top-sm"
          [value]="true"
          i18n
          >From template</mat-radio-button
        >
      </mat-radio-group>
    </div>
    <h1 *ngIf="message" style="color: red">{{ message }}</h1>
    <ng-container *ngIf="!useTemplates.value">
      <mat-form-field>
        <input
          id="project_length"
          matInput
          class="project-length"
          placeholder="Length"
          i18n-placeholder
          type="number"
          [formControl]="length"
        />
      </mat-form-field>
      <mat-form-field>
        <input
          id="project_width"
          matInput
          class="project-width"
          placeholder="Width"
          i18n-placeholder
          type="number"
          [formControl]="width"
        />
      </mat-form-field>
      <mat-form-field>
        <input
          id="project_height"
          matInput
          class="project-height"
          placeholder="Height"
          i18n-placeholder
          type="number"
          [formControl]="height"
        />
      </mat-form-field>
      <mat-form-field>
        <input
          id="wall_thick"
          matInput
          placeholder="Wall thickness"
          i18n-placeholder
          type="number"
          [formControl]="thickness"
        />
      </mat-form-field>
    </ng-container>
    <div *ngIf="useTemplates.value" class="new-project__templates">
      <div
        *ngFor="let template of templates"
        app-project-thumbnail
        [file]="template"
        (click)="createFromTemplate(template)"
      ></div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions
  *ngIf="!useTemplates.value"
  class="new-project__actions"
  fxLayout="row"
>
  <span fxFlex></span>
  <button
    id="create_project"
    mat-raised-button
    color="accent"
    (click)="createProject()"
    [disabled]="creating"
    i18n
  >
    CREATE
  </button>
</mat-dialog-actions>
