import { Component, OnInit, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import {
  FilesService,
  FileItem,
  OrderItem,
  OrderService,
  UserInfo,
  AuthService,
  AccountService,
} from "../../shared";
import { Observable } from "rxjs";
import { NewProjectComponent } from "../new-project/new-project.component";
import {
  CustomButton,
  DialogService,
} from "app/dialogs/services/dialog.service";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { UntypedFormControl } from "@angular/forms";
import {
  concatMap,
  filter,
  map,
  switchMap,
  startWith,
  scan,
} from "rxjs/operators";
import { combineLatest, of } from "rxjs";
import { NewOrderComponent } from "../orders/new-order/new-order.component";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { SystemService, OrderSettings } from "app/shared/system.service";

type ViewMode = "table" | "grid";

enum ProjectView {
  All = 1,
  My = 2,
  Templates = 3,
  Projects = 4,
}

@Component({
  selector: "app-projects",
  templateUrl: "./projects.component.html",
  styleUrls: ["./projects.component.scss"],
})
export class ProjectsComponent implements OnInit {
  update = new EventEmitter<void>();
  search = new BehaviorSubject<string | undefined>(undefined);
  PAGE_SIZE = 50;
  skipCount = new BehaviorSubject(0);
  files$: Observable<{ list: OrderItem[]; columns: string[] }>;
  gallery: Observable<boolean>;
  _viewMode: ViewMode;
  projectView$: Observable<number>;
  authOk: Observable<boolean>;
  sortControl: UntypedFormControl;
  checkPrivate = new UntypedFormControl(true);
  shopControl = new UntypedFormControl(0);
  roleControl = new UntypedFormControl("*");
  orderSettings: OrderSettings;
  shops$: Observable<UserInfo[]>;
  roles$ = this.auth.isAdmin.pipe(
    concatMap((ok) => (ok ? this.account.getRolesArray() : of(this.auth.roles)))
  );
  layoutSmallWith = false;
  layoutXSmallWith = false;

  readonly viewModeStore = "projects.viewMode";
  readonly sortModeStore = "projects.sortMode";

  constructor(
    public auth: AuthService,
    private account: AccountService,
    system: SystemService,
    private filesService: FilesService,
    private dialog: DialogService,
    private router: Router,
    private route: ActivatedRoute,
    private firm: OrderService,
    breakpointObserver: BreakpointObserver
  ) {
    this._viewMode =
      (localStorage.getItem(this.viewModeStore) as ViewMode) || "grid";
    this.sortControl = new UntypedFormControl("date");
    if (auth.admin) {
      this.shops$ = this.firm.getShops().pipe(
        filter((s) => s.length > 0),
        map((s) =>
          s.sort((s1, s2) => {
            let n1 = s1.fullName.toLocaleLowerCase();
            let n2 = s2.fullName.toLocaleLowerCase();
            return n1 < n2 ? -1 : n1 > n2 ? 1 : 0;
          })
        )
      );
    }
    system
      .getSettings(OrderSettings)
      .subscribe((s) => (this.orderSettings = s));
    breakpointObserver
      .observe([Breakpoints.Handset, Breakpoints.Small])
      .subscribe((result) => {
        this.layoutSmallWith = result.matches;
      });
    breakpointObserver.observe([Breakpoints.XSmall]).subscribe((result) => {
      this.layoutXSmallWith = result.matches;
    });
  }

  // display list of some projects
  @Input() projects?: Observable<OrderItem[]>;
  @Input() userId?: number;
  @Output() updateProjectsCount = new EventEmitter();

  ngOnInit() {
    let loader = (
      view: ProjectView,
      privateProjects: boolean,
      search?: string,
      user = 0,
      role = ""
    ) => {
      if (view === ProjectView.Templates) {
        return this.filesService.getProjectTemplates(true) as Observable<
          OrderItem[]
        >;
      }
      let viewMode =
        view === ProjectView.My ? "" : privateProjects ? "all" : "gallery";
      let date = "";
      if (search) {
        search = search.replace(/\b(\d{1,2})\.(\d{1,2})$/, (s, day, month) => {
          let cur = new Date();
          let searchDate = new Date(cur.getFullYear(), +month - 1, +day);
          if (searchDate > cur) {
            searchDate.setFullYear(cur.getFullYear() - 1);
          }
          date = searchDate.toISOString();
          return "";
        });
      }
      this.skipCount.next(0);
      user = this.userId || user;
      if (user > 0 && this.auth.admin) {
        viewMode = "hidden";
      }
      return this.skipCount.pipe(
        concatMap((skip) =>
          this.firm.getOrders(
            viewMode,
            this.PAGE_SIZE,
            skip,
            search,
            date,
            user,
            role
          )
        ),
        scan<OrderItem[], OrderItem[]>(
          (all, current) => [...all, ...current],
          []
        )
      );
    };
    this.sortControl.setValue(
      localStorage.getItem(this.sortModeStore) || "date"
    );
    let sort = this.sortControl.valueChanges.pipe(
      startWith(this.sortControl.value)
    );
    let privateProjects = this.checkPrivate.valueChanges.pipe(
      startWith(this.checkPrivate.value)
    );
    let shop = this.shopControl.valueChanges.pipe(
      startWith(this.shopControl.value)
    );
    let role = this.roleControl.valueChanges.pipe(
      startWith(this.roleControl.value)
    );
    let update = this.update.pipe(startWith(undefined));
    let auth = this.auth.isAuthenticated.pipe(filter((v) => v !== undefined));

    if (this.projects) {
      this.viewMode = "table";
      this.projectView$ = of(ProjectView.All);
      this.files$ = this.projects.pipe(
        map((list) => this.transformFiles(list, "", ProjectView.Projects))
      );
    } else {
      this.projectView$ = this.route.data.pipe(
        map((d) => (d.view as number) || ProjectView.Projects)
      );
      this.files$ = combineLatest([
        this.projectView$,
        auth,
        privateProjects,
        update,
        this.search,
        shop,
        role,
      ]).pipe(
        switchMap((params) =>
          loader(
            params[0],
            this.auth.admin && params[2],
            params[4],
            params[5],
            params[6]
          )
        ),
        switchMap((files) =>
          combineLatest([of(files), sort, this.projectView$])
        ),
        map((result) =>
          this.transformFiles(result[0] as OrderItem[], result[1], result[2])
        )
      );
      this.sortControl.valueChanges.subscribe((value) =>
        localStorage.setItem(this.sortModeStore, value)
      );
    }
    this.authOk = combineLatest([
      this.projectView$,
      this.auth.isAuthenticated,
    ]).pipe(map((result) => result[1] || result[0] === ProjectView.All));
  }

  transformFiles(files: OrderItem[], sort: string, view: ProjectView) {
    let list = files.slice();
    let prices = false;
    for (let order of list) {
      if (order.price > 0) {
        prices = true;
      }
      order.name = order.name || "";
    }
    if (sort === "name") {
      list.sort((f1, f2) => {
        let n1 = f1.name.toLocaleLowerCase();
        let n2 = f2.name.toLocaleLowerCase();
        return n1 < n2 ? -1 : n1 > n2 ? 1 : 0;
      });
    } else if (sort === "price") {
      list.sort((f1, f2) => f2.price - f1.price);
    } else {
      list.sort((f1, f2) => {
        return (
          new Date(f2.modifiedAt).valueOf() - new Date(f1.modifiedAt).valueOf()
        );
      });
    }
    let galleryColumns = ["name"];
    if (!this.layoutXSmallWith) {
      galleryColumns.push("ownerName");
    }
    if (this.auth.admin) {
      if (!this.layoutSmallWith) {
        galleryColumns.push("date", "client");
      }
      galleryColumns.push("delete");
    }
    let myColumns = ["name"];
    if (!this.layoutSmallWith) {
      myColumns.push("client");
    }
    if (!this.layoutXSmallWith) {
      myColumns.push("date");
    }
    myColumns.push("delete");
    let templateColumns = ["name", "date"];
    let projectColumns = templateColumns;
    let columns = [galleryColumns, myColumns, templateColumns, projectColumns][
      view - 1
    ];
    if (columns === projectColumns && list.length > 0) {
      if (list.some((p) => p.ownerId !== list[0].ownerId)) {
        columns = galleryColumns;
      }
    }
    if (view !== ProjectView.Templates && prices) {
      columns.splice(1, 0, "price");
    }
    if (this.userId) {
      if (this.layoutXSmallWith) {
        columns.splice(1, 1);
      }
      if (!this.layoutSmallWith) {
        columns.push("client");
      }
      columns.push("delete");
    }
    return { list, columns };
  }

  get viewMode() {
    return this._viewMode;
  }

  set viewMode(value) {
    this._viewMode = value;
    localStorage.setItem(this.viewModeStore, this.viewMode);
  }

  runSearch(value?: string) {
    this.search.next(value);
  }

  newPlan(view: ProjectView) {
    if (
      this.orderSettings &&
      this.orderSettings.enabled &&
      view === ProjectView.My
    ) {
      this.dialog.open(NewOrderComponent);
    } else {
      this.dialog
        .open(NewProjectComponent, { data: view === ProjectView.Templates })
        .componentInstance.afterCreate.subscribe((f) =>
          this.router.navigate(["/project", f.id])
        );
    }
  }

  openProject(_, p: FileItem) {
    this.router.navigate(["/project", p.id]);
  }

  canRemove(p: OrderItem) {
    if (p.removed) {
      return false;
    }
    return this.auth.admin || p.ownerId === this.auth.userId;
  }

  removeProject(event: MouseEvent, p: FileItem) {
    enum RemoveActions {
      Remove = "remove",
      RemovePermanently = "removePermanently",
    }

    const customButtons: CustomButton[] = [
      {
        actionId: RemoveActions.Remove,
        name: $localize`Remove`,
      },
    ];
    if (this.auth.admin) {
      customButtons.push({
        actionId: RemoveActions.RemovePermanently,
        name: $localize`Remove permanently`,
      });
    }

    event.stopPropagation();
    this.dialog
      .openConfirm({
        message: $localize`Remove project ${p.name}?`,
        customButtons,
      })
      .afterClosed()
      .pipe(
        filter((action) => action),
        concatMap((action) => {
          switch (action) {
            case RemoveActions.Remove:
              return this.filesService.removeFile(p);
            case RemoveActions.RemovePermanently:
              return this.filesService.removeFile(p, true);
          }
        })
      )
      .subscribe((_) => {
        this.updateProjectsCount.emit();
        this.update.next();
      });
  }

  canRestore(p: OrderItem) {
    return !!p.removed;
  }

  restoreProject(event: MouseEvent, p: FileItem) {
    event.stopPropagation();
    this.dialog
      .openConfirm({
        message: $localize`Restore project ${p.name}?`,
      })
      .afterClosed()
      .pipe(
        filter((ok) => ok),
        concatMap((_) => this.filesService.restoreFiles([p]))
      )
      .subscribe((_) => {
        this.updateProjectsCount.emit();
        this.update.next();
      });
  }

  displayMoreProjects() {
    this.skipCount.next(this.skipCount.value + this.PAGE_SIZE);
  }
}
