import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface ErrorItem {
  expression: string;
  errors: string[];
  variables: {
    name: string;
    value: string;
  }[];
}

@Component({
  selector: "app-model-analyze",
  templateUrl: "./model-analyze.component.html",
  styleUrls: ["./model-analyze.component.scss"],
})
export class ModelAnalyzeComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ModelAnalyzeComponent>,
    @Inject(MAT_DIALOG_DATA) public errorData: ErrorItem[]
  ) {}

  ngOnInit() {
    this.dialogRef.updateSize("85vw", "70vh");
  }
}
