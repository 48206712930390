import { BreakpointObserver } from "@angular/cdk/layout";
import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Observable } from "rxjs";

export interface ProjectLinkQRCodeData {
  url: Observable<string>;
}

@Component({
  selector: "app-project-link-qr-code",
  templateUrl: "./project-link-qr-code.component.html",
  styleUrls: ["./project-link-qr-code.component.scss"],
})
export class ProjectLinkQRCodeComponent {
  qrCodeWidth = 512;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ProjectLinkQRCodeData,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver
      .observe(["(max-width: 700px)", "(max-height: 600px)"])
      .subscribe((result) => {
        if (result.matches) {
          this.qrCodeWidth = 256;
        }
      });
  }
}
