import {
  Entity,
  Designer,
  BuilderApplyItem,
  EntityAttributes,
} from "modeler/designer";
import { FilesService, FileItem, CatalogService } from "app/shared";
import { mat4, Size } from "modeler/geometry";
import { DimensionOptions, ModelHandler } from "modeler/model-handler";
import { pb } from "modeler/pb/scene";
import { generateUid } from "modeler/syncer";
import { util as pbutil } from "protobufjs";
import { MaterialMapper } from "modeler/material-utils";
import { ContainerManager } from "modeler/container";

export interface CreateDimensionOptions extends DimensionOptions {
  points?: [Float64Array, Float64Array, Float64Array];
  dimension?: Entity;
  parent?: Entity;
  attributes?: EntityAttributes;
  layer?: string;
}

export class ProjectEditorInterface {
  constructor(
    private ds: Designer,
    private files: FilesService,
    private catalog: CatalogService,
    private batchMode = false,
    private amend = false
  ) {}

  private allChanges: BuilderApplyItem[] = [];
  private flushModels: string[] = [];

  async apply(changes: BuilderApplyItem[], name = "script", flush?: string) {
    if (this.batchMode) {
      this.allChanges.push(...changes);
      if (flush) {
        this.flushModels.push(flush);
      }
    } else {
      await this.ds.applyBatch(
        name,
        changes,
        undefined,
        this.amend ? "amend" : "",
        flush
      );
    }
  }

  async insert(id: number, matrix?: number[] | Float64Array, parent?: Entity) {
    let file = await this.files.getFile(id).toPromise();
    if (matrix && matrix.length === 3) {
      matrix = mat4.ffromTranslation(matrix[0], matrix[1], matrix[2]);
    }
    let modelId = generateUid().toString();
    await this.apply(
      [
        {
          uid: parent ? parent.uidStr : "root",
          insert: {
            insertModelId: id,
            modelName: file.name,
            matrix,
            modelId,
            sku: file.sku,
          },
        },
      ],
      "Insert model",
      id.toString()
    );
    let model = this.ds.entityMap[modelId];
    return model || modelId;
  }

  async remove(e: Entity | Entity[]) {
    if (e instanceof Entity) {
      e = [e];
    }
    let changes = e.map((e) => ({ uid: e, remove: true }));
    return this.apply(changes, "Remove models");
  }

  async replace(src: Entity | Entity[], dest: FileItem) {
    if (src instanceof Entity) {
      src = [src];
    }
    let changes: BuilderApplyItem[] = src.map((e) => ({
      uid: e,
      replace: {
        insertModelId: dest.id.toString(),
        modelName: dest.name,
        sku: dest.sku,
      },
    }));
    return this.apply(changes, "Replace models", dest.id.toString());
  }

  createDimension3P(options: CreateDimensionOptions) {
    let e = options.dimension || this.ds.root.addChild();
    if (options.points) {
      // convert plain arrays to typed arrays
      let p1 = new Float64Array(options.points[0]);
      let p2 = new Float64Array(options.points[1]);
      let p3 = new Float64Array(options.points[2]);
      if (!ModelHandler.initDimension3P(e, p1, p2, p3)) {
        console.error("Failed to init dimension. Invalid geometry");
        return undefined;
      }
    }
    ModelHandler.assignDimensionOptions(e, options);
    if (options.parent) {
      e.parent = options.parent;
    }
    let change = ModelHandler.createOrUpdateDrawing(e);
    if (options.parent) {
      change.parent = options.parent;
    }
    if (options.attributes) {
      change.data.attributes = options.attributes;
    }
    change.layer = options.layer;
    this.apply([change], "Create size");
    return e;
  }

  getDimensionParams(e: Entity) {
    if (e.drawing instanceof Size) {
      return {
        value: e.drawing.value,
        fontSize: e.drawing.fontSize,
        prefix: e.drawing.prefix,
        postfix: e.drawing.postfix,
        layer: e.layer?.name,
        attributes: e.data.attributes,
        points: {
          p1: e.drawing.p1.toVec3(),
          p2: e.drawing.p2.toVec3(),
        },
      };
    }
  }

  async setPosition(
    e: Entity,
    data: { matrix?: Float64Array; parent?: Entity; parentIndex?: number }
  ) {
    this.apply([
      {
        uid: e,
        matrix: data.matrix,
        parent: data.parent,
        parentIndex: data.parentIndex,
      },
    ]);
  }

  async setPropertyValue(
    src: Entity | Entity[],
    property: number | string,
    value: number | string
  ) {
    if (src instanceof Entity) {
      src = [src];
    }
    let changes = await ModelHandler.computePropertyChanges(
      src,
      this.catalog.newPropertyLoader(),
      undefined,
      (mp) => {
        for (let prop of mp.props) {
          if (prop.name === property || prop.id === property) {
            let variant = prop.variants.find(
              (v) => v.name === value || v.id === value
            );
            if (variant) {
              prop.value = variant.id;
            }
          }
        }
      }
    ).toPromise();
    return this.apply(changes);
  }

  getParameter(src: Entity, name: string, includeChildren = false) {
    if (src.elastic && src.elastic.params) {
      let param = src.elastic.params.find((p) => p.name === name);
      if (param) {
        return param.size;
      }
    }
    if (includeChildren && src.children) {
      for (let child of src.children) {
        let size = this.getParameter(child, name, true);
        if (size !== undefined) {
          return size;
        }
      }
    }
  }

  async setParameter(src: Entity | Entity[], name: string, value: number) {
    if (src instanceof Entity) {
      src = [src];
    }
    let changes = src.map((e) => ({ uid: e, size: { [name]: value } }));
    return this.apply(changes, "Resize model");
  }

  getLayer(e: Entity) {
    return e.layer?.name;
  }

  setLayer(e: Entity, layer: string) {
    return this.apply([{ uid: e, layer }], "Set layer");
  }

  getModelInfo(e: Entity) {
    return e.data && e.data.model;
  }

  getMaterialMapper(e: Entity) {
    let mapper = new MaterialMapper(e);
    return (s) => mapper.map(s);
  }

  async getBuilder(e: Entity) {
    let builder = await e.getBuilder();
    return new EntityBuilderEditor(e, builder, this);
  }

  async finish() {
    if (this.allChanges.length > 0) {
      await this.ds.applyBatch(
        "Script editor",
        this.allChanges,
        undefined,
        this.amend ? "amend" : "",
        this.flushModels
      );
    }
    this.allChanges = [];
    this.flushModels = [];
  }

  isElasticContainer(e: Entity) {
    return ContainerManager.isElasticContainer(e);
  }

  getMovingAxis(e: Entity) {
    return ContainerManager.getMovingAxis(e?.elastic?.position);
  }

  findNeighbourContainers(entityOrContainer: Entity, axis: number) {
    let containers = ContainerManager.findNeighbourContainers(
      entityOrContainer,
      axis
    );
    return containers;
  }
}

class EntityBuilderEditor {
  constructor(
    private e: Entity,
    private builder: pb.IBuilder,
    private editor: ProjectEditorInterface
  ) {}

  get material() {
    return this.builder.material;
  }

  get isPanel() {
    return !!this.builder.panel;
  }

  get textureOrientation() {
    return this.builder.panel.texture;
  }

  set textureOrientation(value: number) {
    this.builder.panel.texture = value;
  }

  async save() {
    let component = pb.BuilderComponent.create({ builder: this.builder });
    let error = pb.BuilderComponent.verify(component);
    if (error) {
      console.error("Invalid builder:", this.builder);
      throw error;
    }
    let binary = pb.BuilderComponent.encode(component).finish();
    let base64 = pbutil.base64.encode(binary, 0, binary.length);
    await this.editor.apply([{ uid: this.e, builder: base64 }]);
    return this.e.meshes && this.e.meshes.length > 0;
  }
}
