import { Injectable, Type } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { of, Observable } from "rxjs";
import { isPromise } from "./utils";
import { environment } from "environments/environment";
import { NavigationMode } from "modeler/designer";
import { RenderPreset } from "./render.service";
import { CloudApiMode } from "./cloud.service";

export interface EmailResult {
  ok: boolean;
  error?: string;
}

export enum ReportType {
  Print = 0,
  Xml = 1,
  Email = 2,
}

export interface AuthTokens {
  accessToken?: string;
  refreshToken?: string;
}

export interface InitConfiguration {
  // provide tokens to auth service
  tokens?: AuthTokens | Promise<AuthTokens>;
  // login as specified user (user with 'embeded' role)
  loginUserId?: number;
  // load different routes based on mode
  mode?: "editor" | "embedded" | "store";
  // url to post order info
  orderUrl?: string;
}

export class BaseConfig {}

export class ApplicationConfig {
  applicationName = "WebPlanner";
  registrationEnabled = false;
  companyLogoName = "";
}

export class SmsServerConfig extends BaseConfig {
  server: string;
  bodyTemplate: string;
  bodyType: string;
  phoneRewriteRules?: { pattern: string; replacement: string };
}

export class ArchiveConfig extends BaseConfig {
  enableArchiving: boolean;
  destination: string;
  archiveAfter: number;
  removeAfter: number;
  everydayBackup: boolean;
  saveOnS3: boolean;
  bucketName: string;
}

export class S3ProjectsStorageConfig extends BaseConfig {
  enableS3Storage: boolean;
  sendAfter: number;
  bucketName: string;
}

export class S3BaseConfig extends BaseConfig {
  accessKey: string;
  secretKey: string;
  endpoint: string;
}

export class RenderConfig extends BaseConfig {
  renderUrl: string;
  userUrl: string;
  renderStandartPreset: RenderPreset;
  render360Preset: RenderPreset;
  hideRoomElements: boolean;
}

export class CloudConfig extends BaseConfig {
  cloudApiMode: number;
  cloudApiKey: string;
  cloudApiKeyDev: string;
  estimateMode: number;
  calculateChangedItems: boolean;
}

const configNameMap = {
  application: ApplicationConfig,
  smsserver: SmsServerConfig,
  archive: ArchiveConfig,
  S3ProjectsStorage: S3ProjectsStorageConfig,
  S3BaseConfig: S3BaseConfig,
  RenderConfig: RenderConfig,
  CloudConfig: CloudConfig,
};

export interface BaseSettings {
  $name: string;
}

export class AngstremSettings {
  $name = "AngstremSettings";
  priceFtp: string;
}

export class PlannerSettings {
  $name = "PlannerSettings";
  showPrices = false;
  sounds = true;
  doorAnimation = true;
  showFloors = true;
  replaceByType = true;
  navigatorCube = true;
  drawSilhoutte = false;
  updateChangedModels = true;
  defaultRenderMode = 1;
  defaultCameraMode = NavigationMode.Orbit;
  defaultFovAngle = 60.0;
  shortLinks = false;
  selectionOutlineColor = "#FF9900";
}

export class EmbeddedSettings {
  $name = "EmbeddedSettings";
  enabled = true;
  userName: string;
  configUrl?: string; // can be used in addition to query params
  priceUrl?: string;
  orderUrl?: string;
}

export class OrderSettings {
  $name = "OrderSettings";
  enabled = true;
  // auto generate project name
  projectName?: string;
  // order button and actions
  order = false;
  lock = false;
  verifyPhone = false;
  requirePhone = false;
  canArchive?: boolean;
  email: string;
  customParams: any[];
  customClientParams: any[];
}

export class MailSettings {
  $name = "MailSettings";
  host: string;
  port = 465;
  ssl = true;
  disableSslCheck = false;
  userName: string;
  password: string;
  senderEmail: string;
  adminName: string;
  adminEmail: string;
  sendMailToAuthor: boolean;
  additionalEmails: string;
}

export interface Report {
  type: ReportType;
  id: number;
  name: string;
  params?: any;
  template?: string;
  style?: string;
}

@Injectable({
  providedIn: "root",
})
export class SystemService {
  constructor(private http: HttpClient) {
    if (environment.e2e) {
      this.config.registrationEnabled = true;
    }
  }

  config = SystemService.appConfig;
  readonly initConfig = SystemService.initConfig;

  getAssets(path = "") {
    return this.http.get<{ folders: string[]; files: string[] }>(
      `/api/system/assets/` + path
    );
  }

  getAsset(path: string) {
    return this.http.get(`/api/system/asset/${path}`, { responseType: "blob" });
  }

  uploadAsset(path: string, content: File | string | null) {
    let data = new FormData();
    if (typeof content === "string") {
      content = new File([content], path, { type: "text/plain" });
    }
    if (content) {
      data.append("file", content, content.name);
    }
    return this.http.post(`/api/system/asset/${path}`, data);
  }

  removeAsset(name: string) {
    return this.http.delete("/api/system/asset/" + name);
  }

  getScript(name: string) {
    return this.http.get(`/api/system/script/${name}`, {
      responseType: "text",
    });
  }

  setScript(name: string, source: string) {
    return this.http.post(`/api/system/script/${name}`, { source });
  }

  getStyle() {
    return this.http.get("/api/system/webstyle", { responseType: "text" });
  }

  setStyle(style: string) {
    return this.http.post("/api/system/webstyle", { style });
  }

  sendEmail(address: string, body: string) {
    return this.http
      .post<EmailResult>("/api/system/email", { address, body })
      .pipe(
        catchError((error) => {
          let message = `${error}`;
          if (error instanceof HttpErrorResponse) {
            message = error.message;
          }
          return of<EmailResult>({ ok: false, error: message });
        })
      );
  }

  testEmail(settings: MailSettings) {
    return this.http.post<EmailResult>("/api/system/testemail", settings);
  }

  proxyPost<T>(url: string, data: any) {
    const proxyPrefix = "proxy://";
    if (url.startsWith(proxyPrefix)) {
      let body = { url: url.substr(proxyPrefix.length), data };
      return this.http.post<T>("/api/system/proxy", body);
    }
    return this.http.post<T>(url, data);
  }

  getTemplates() {
    return this.http.get<Report[]>("/api/system/reports");
  }

  getTemplate(id: number) {
    return this.http.get<Report>("/api/system/report/" + id).pipe(
      map((r) => {
        r.params = r.params ? JSON.parse(r.params as any) : undefined;
        return r;
      })
    );
  }

  editTemplate(report: Report) {
    return this.http.post<number>("/api/system/report", report);
  }

  removeTemplate(report: Report) {
    return this.http.delete<number>("/api/system/report/" + report.id);
  }

  builderStatus() {
    return this.http.get("/api/system/builder");
  }

  getBackgroundJobs() {
    return this.http.get<any>("/api/system/background-jobs");
  }

  closeOpenedModels() {
    return this.http.post("/api/system/builder/closemodels", {});
  }

  backup() {
    return this.http.post<string>("/api/system/backuptask", {});
  }

  archiveBackups() {
    return this.http.post<string>("/api/system/archivebackups", {});
  }

  angstremUpdate() {
    return this.http.post("/api/system/angstremupdate", { ftpUrl: "default" });
  }

  stopJob(id: string) {
    return this.http.post("/api/system/stopjob/" + id, {});
  }

  sendSmsCode(phone: string) {
    return this.http.post(
      "/api/system/sendsmscode",
      { phone },
      { responseType: "text" }
    );
  }

  verifySmsCode(phone: string, code: string) {
    return this.http.post<boolean>("/api/system/verifysmscode", {
      phone,
      code,
    });
  }

  getConfig<T extends BaseConfig>(type: Type<T>): Observable<T> {
    for (let key in configNameMap) {
      if (configNameMap[key] === type) {
        return this.http
          .get<any>(`/api/system/config/${key}`)
          .pipe(map((data) => Object.assign(new type(), data)));
      }
    }
  }

  setConfig<T extends BaseConfig>(value: T) {
    if (value instanceof ApplicationConfig) {
      this.config = value;
    }
    for (let key in configNameMap) {
      if (value instanceof configNameMap[key]) {
        return this.http.post<T>(`/api/system/config/${key}`, value);
      }
    }
  }

  getSettings<T extends BaseSettings>(type: Type<T>): Observable<T> {
    let result = new type();
    return this.http
      .get<any>(`/api/system/appsetting/${result.$name}`)
      .pipe(map((data) => Object.assign(result, data)));
  }

  setSettings<T extends BaseSettings>(value: T, userId?: number) {
    let savedValue = { ...value };
    delete savedValue.$name;
    return this.http.post("/api/system/appsetting", {
      name: value.$name,
      userId,
      value: JSON.stringify(savedValue),
    });
  }

  shortenUrl(url: string) {
    return this.http.post(
      "/api/system/shortlink",
      { url },
      { responseType: "text" }
    );
  }

  restoreUrl(shortUrl: string) {
    return this.http.get(`/api/system/shortlink/${shortUrl}`, {
      responseType: "text",
    });
  }

  static get initConfig() {
    if (typeof window === "undefined") {
      return {};
    }
    return (window["wp_init_config"] || {}) as InitConfiguration;
  }

  static get appConfig() {
    let result = new ApplicationConfig();
    if (typeof window !== "undefined") {
      Object.assign(result, window["wp_app_config"] || {});
    }
    return result;
  }

  static async resolveInitConfiguration() {
    if (isPromise(this.initConfig)) {
      window["wp_init_config"] = await this.initConfig;
    }
    return this.initConfig;
  }
}
