<mat-dialog-content
  *ngIf="errorData.length > 0; else errorsNotFound"
  class="mat-typography"
>
  <mat-expansion-panel *ngFor="let item of errorData">
    <mat-expansion-panel-header>
      <mat-panel-title i18n>Expression: {{ item.expression }}</mat-panel-title>
    </mat-expansion-panel-header>

    <div fxLayout="column" fxLayoutGap="10px" class="model-analyze__errors">
      <span
        fxFlex
        *ngFor="let error of item.errors"
        class="model-analyze__errors-item"
        >{{ error }}</span
      >
    </div>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title i18n
          >Available variables: {{ item.variables.length }}</mat-panel-title
        >
      </mat-expansion-panel-header>

      <mat-nav-list *ngFor="let variable of item.variables">
        <span fxFlex>
          <span class="model-analyze__variable-name">{{ variable.name }}</span
          >: {{ variable.value }}
        </span>
      </mat-nav-list>
    </mat-expansion-panel>
  </mat-expansion-panel>
</mat-dialog-content>

<ng-template #errorsNotFound>
  <h3 i18n class="error-msg" fxLayout="column">Errors not found</h3>
</ng-template>
