<section class="push">
  <h1 class="push-xxl-top mat-title" i18n>404 Not found</h1>
  <h3 class="mat-subheading-2" i18n>
    Sorry but we could not find the page you are looking for
  </h3>
  <div class="not-found__options push-top">
    <a color="primary" mat-raised-button routerLink="/projects" i18n
      >Go to projects</a
    >
    <a color="primary" mat-raised-button routerLink="/catalog/list" i18n
      >View catalogs</a
    >
  </div>
</section>
